<template>
  <div>
    <b-card>
      <b-card-title>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div
            class="text-left order-2 mt-1 mt-md-0 order-md-1 w-100 w-md-auto"
          >
            <div class="d-flex w-100 w-md-auto align-items-center">
              <b-form-group class="mb-0 w-md-auto">
                <label class="text-sm-left">Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    @change="checkEmptySearchInput"
                    id="filterInput"
                    v-model="search"
                    type="search"
                    placeholder="Enter Name / Email"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="searchConsultants"
                      :disabled="!search"
                    >
                      Search
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group class="ml-1 mb-0 w-md-auto">
                <label class="text-sm-left">Sector</label>
                <b-input-group size="sm">
                  <b-form-select
                    @change="sectorChanged"
                    id="sortBySelect"
                    v-model="sectorSelected"
                    :options="sectors"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="text-right order-1 order-md-2 w-100 w-md-auto">
            <b-button @click="initNewConsultantAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>
              Add New</b-button
            >
          </div>
        </div>
      </b-card-title>
      <b-table
        @row-clicked="onRowClicked"
        :busy="isBusy"
        :items="consultants"
        hover
        :fields="fields"
        responsive
        show-empty
      >
        <template #empty="scope">
          <div class="text-center">
            <p>0 consultants found!</p>
            <b-button @click="initNewConsultantAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>

              Add New
            </b-button>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
            <strong class="ml-1">Loading consultants...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <b-avatar rounded size="32" variant="light-company">
              <b-img
                :src="data.item.avatar ? data.item.avatar : defaultAvatar"
                alt="avatar img"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder">
                {{ data.item.name }}
                <b-badge
                  v-b-tooltip.hover.top="'Pending account activation!'"
                  v-if="!data.item.activated"
                  class="font-weight-light"
                  size="sm"
                  variant="light-warning"
                  ><feather-icon icon="ClockIcon"></feather-icon>
                </b-badge>
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.sector }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-b-tooltip.hover.top="'Edit Consultant'"
              size="sm"
              @click="initEditConsultant(data)"
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-footer>
        <b-row>
          <b-col cols="3">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                @change="sectorChanged"
                id="perPageSelect"
                v-model="limit"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="offset-3">
            <b-pagination
              @change="paginate"
              class="justify-content-end"
              v-model="page"
              :per-page="limit"
              :total-rows="totalConsultants"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <CreateConsultantModal
      :isBusy="isModalBusy"
      :consultant="consultant"
      :show="showCreateConsultantModal"
      :action="action"
      @closeConsultantPopUp="closeConsultantPopUp"
      @added="addedNew"
      @updated="updatedConsultant"
    />
  </div>
</template>

<script>
import {
  BTable,
  BCardTitle,
  BCardFooter,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BImg,
  VBTooltip,
  BFormSelect,
} from "bootstrap-vue";
import axios from "axios";
import CreateConsultantModal from "@/components/consultant/CreateConsultantModal.vue";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    CreateConsultantModal,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
    BFormSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "index", label: "S.No" },
        { key: "name", label: "Name" },
        "email",
        "city",
        "actions",
      ],
      defaultAvatar:
        "https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/toolbox.2bbf519b.svg",
      consultants: [],
      consultant: {
        sector: null,
      },
      showCreateConsultantModal: false,
      limit: 10,
      page: 1,
      search: "",
      totalConsultants: 0,
      isBusy: true,
      isModalBusy: false,
      action: "add-new",
      sectors: [],
      sectorSelected: "",
      pageOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    closeConsultantPopUp() {
      this.showCreateConsultantModal = false;
      this.resetConsultantModal();
    },
    initNewConsultantAdd() {
      this.$set(this, "showCreateConsultantModal", true);
    },
    resetPagination() {
      this.$set(this, "page", 1);
      this.$set(this, "search", "");
    },
    resetConsultantModal() {
      this.$set(this, "consultant", {
        sector: null,
      });
      this.$set(this, "action", "add-new");
    },
    addedNew() {
      this.$set(this, "showCreateConsultantModal", false);
      this.resetPagination();
      this.resetConsultantModal();
      this.getConsultants();
    },
    updatedConsultant() {
      this.$set(this, "showCreateConsultantModal", false);
      this.resetConsultantModal();
      this.getConsultants();
    },
    getConsultants() {
      this.isBusy = true;
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/consultant?limit=${this.limit}&page=${this.page}&search=${this.search}&sector=${this.sectorSelected}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.consultants = response.data.consultants;
          this.totalConsultants = response.data.totalConsultants;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
    onRowClicked(item) {
      this.$router.push(`/consultants/${item._id}`);
    },
    initEditConsultant(data) {
      let consultant = data.item;
      this.$set(this, "consultant", consultant);
      this.$set(this, "showCreateConsultantModal", true);
      this.$set(this, "action", "edit");
    },
    checkEmptySearchInput(value) {
      if (!value) {
        this.resetPagination();
        this.getConsultants();
      }
    },
    sectorChanged() {
      this.$set(this, "page", 1);
      this.getConsultants();
    },
    paginate(value) {
      this.$set(this, "page", value);
      this.getConsultants();
    },
    searchConsultants() {
      this.$set(this, "page", 1);
      this.getConsultants();
    },
    getSectors() {
      let token = this.$getUserToken();
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/sector`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.sectors = response.data.sectors.map((sector) => {
            return sector.name;
          });
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getConsultants();
    this.getSectors();
  },
};
</script>
